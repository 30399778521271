import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useDevicesList() {
  // Use toast
  const toast = useToast()

  const refDeviceListTable = ref(null)


  // Table Handlers
  const tableColumns = [
    { key: 'customerName', label: 'Customer', formatter: title, sortable: false },
    { key: 'modemIdentifier', label: 'Identifier', formatter: title, sortable: false },
    { key: 'deviceModelName', label: 'Device Model', formatter: title, sortable: false },
    { key: 'mobileOperatorName', label: 'Mobile Operator', formatter: title, sortable: false },
    { key: 'mobileOperatorProviderName', label: 'Mobile Provider', formatter: title, sortable: false },
    { key: 'simCardNumber', label: 'Sim Card', formatter: title, sortable: false },
    { key: 'assetPlate', label: 'Asset Idenfier', formatter: title, sortable: false },
    { key: 'actions' },
  ]
/*

      "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
      "modemIdentifier": "string",
      "deviceModelName": "string",
      "mobileOperatorName": "string",
      "mobileOperatorProviderName": "string",
      "simCardNumber": "string",
      "assetPlate": "string",
      "assetChassis": "string",
      "customerName": "string",
*/


  const perPage = ref(10)
  const totalDevices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  

  const dataMeta = computed(() => {
    const localItemsCount = refDeviceListTable.value ? refDeviceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDevices.value,
    }
  })

  const refetchData = () => {
    refDeviceListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchDevices = (ctx, callback) => {
    store
      .dispatch('app-device/fetchDevices', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        //const { devices, total } = response.data
          const devices = response.data.data;
          const total = response.data.length;

        callback(devices)
        totalDevices.value = response.data.totalItems
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching devices list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  

  return {
    fetchDevices,
    tableColumns,
    perPage,
    currentPage,
    totalDevices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refDeviceListTable,

    
    refetchData,

    // Extra Filters
   
  }
}
